.heading {
    color: #022345 !important;
    font-size: 40px;
    font-family: 'Heading' !important;
    letter-spacing: normal;
    text-align: center;
}

@keyframes rotate {
    50% {transform: rotate(180deg);}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .heading {
        font-size: 30px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1296px) {
  
}