.btnContainer{
    align-content: center;
    display:flex;
    align-items: center;
    justify-content: center;
}
.rulesBtn {
    font-family: sunf;
    color: #002245 !important;
    font-size: 28px !important;
    font-family: 'Para' !important;
    text-align: center;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    background-color: #E3E7EB;
    border-radius: 10px;
    margin: 15px;
    transition-duration: 0.7s;
    cursor: pointer;
}

.rulesBtn:hover{
    transform: scale(1.2);
    background-color: #002245;
    color: #E3E7EB !important;
}

.boxStyle{
    /* transition-duration: 1s; */
    background-color: #E3E7EB;
    border-radius: 16px;
    padding: 30px !important;
    width: 80vw;
    display: block !important;
}

.boxHeading{
    color: #002245;
    text-align: center;
    font-family:  "Heading" !important;
    font-size: large;
    text-decoration: underline;
    /* overflow-y: scroll; */
}

.listItem{
    font-family: "Para" !important;
    font-weight: lighter;
    list-style-type: none;
    color: #002245;
}

.bulletImg{
    width: 2rem;
    display: inline-flex;
}

@media only screen and (max-width: 600px) {
    .boxStyle {
        width: 90% !important;
        padding: 10px 0 !important;
        height: fit-content !important;
    }
}