.img-gallery-card {
    /* color: #717274 !important;
    background-color: #ffffff; */
    border-radius: 10px;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: center;
}

.img-gallery-card-title {
    color: #ffffff !important;
    font-size: 28px !important;
    font-family: 'Para' !important;
    text-align: center;
    margin: 0px 0px 0px 20px;
    width: 50%;
    padding: 5px 10px 5px 10px;
    background-color: #08315b;
    border-radius: 10px;
    margin-top: 15px;
}

.img-gallery-card-top {
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    color: #ffffff !important;
    /* background-color: #DFE3E9; */
}

.img-gallery-img-cont {
    height: 200px;
    width: auto;
    border-radius: 10px;
    overflow: hidden;
}

.img-gallery-img {
    height: 100%;
    width: auto;
    border-radius: 10px;
}