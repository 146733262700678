.simplified-button {
	border: 0;
	cursor: pointer;
	background-color: transparent;
	font-size: 1rem;
	width: fit-content;
}
.simplified-button:hover {
	text-decoration: underline;
}
