.landing-container-div {
    min-height: 100vh;
    background-color: #DFE5E8;
}

.landing-container {
    min-height: 100vh;
}

.landing-heading1 {
    color: #202020 !important;
    font-size: 60px !important;
    font-family: 'Heading' !important;
    letter-spacing: normal;
    width: 80%;
    text-align: center;
    line-height: 1.5;
    padding-left: 80px;
    animation: topToBottom 2s forwards;
}

@keyframes topToBottom {
    from {
        transform: translateY(20px);
        /* Start from top */
        opacity: 0;
        /* Start with opacity 0 */
    }

    to {
        transform: translateY(0);
        /* End at original position */
        opacity: 1;
        /* Gradually fade in */
    }
}

.landing-heading-letter {
    font-size: 120px;
    line-height: 1;
}

.landing-img {
    height: auto;
    width: 850px;
}

.fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.fade-in.fade-in-active {
    opacity: 1;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .landing-container-div {
        min-height: 40vh;
        height: fit-content;
    }

    .landing-container {
        min-height: fit-content;
    }

    .landing-heading1 {
        font-size: 20px !important;
        padding: 0px;
        width: 100%;
        margin-top: 3vh;
    }

    .landing-heading-letter {
        font-size: 40px;
    }

    .landing-img {
        /* display: none; */
        width: 100%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .landing-container-div {
        /* min-height: 40vh; */
    }

    .landing-container {
        min-height: fit-content;
    }

    .landing-heading1 {

        font-size: 30px !important;

    }


    .landing-heading-letter {
        font-size: 80px;
    }

    .landing-img {
        width: 50px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .landing-container-div {
        min-height: 50vh;
    }

    .landing-heading1 {

        font-size: 30px !important;
        width: 60%;
        line-height: 1.5;
    }


    .landing-heading-letter {
        font-size: 100px;
    }

    .landing-img {
        width: 250px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .landing-container-div {
        min-height: 90vh;
    }

    .landing-heading1 {

        font-size: 47px !important;

    }


    .landing-heading-letter {
        font-size: 120px;
    }

    .landing-img {
        width: 450px;
    }

}

@media only screen and (min-width: 1100px) {
    .landing-container-div {
        min-height: 90vh;
    }

    .landing-heading1 {

        font-size: 50px !important;

    }

    .landing-space {

        min-height: 15vh;

    }

    .landing-heading-letter {
        font-size: 120px;
    }

    .landing-img {
        width: 550px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1296px) {
    .landing-container-div {
        min-height: 90vh;
    }

    .landing-heading1 {

        font-size: 60px !important;

    }

    .landing-space {

        min-height: 10vh;

    }

    .landing-heading-letter {
        font-size: 120px;
    }

    .landing-img {
        width: 850px;
    }
}