.timelineBoxLeft{
    background-color: #002245;
    color: white;
    width: fit-content;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:lighter;
    padding: 20px;
    border-radius: 0 20px 20px 20px;
    text-align: left;
}
.timelineBoxRight{
    background-color: #002245;
    color: white;
    width: fit-content;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight:lighter;
    padding: 20px;
    border-radius: 20px 0 20px 20px;
    text-align: left;
}

h5{
    margin: 0;
    padding: auto;
    color: white;
    text-align: left;
}

h4{
    font-family:  "Para" !important;
    font-weight: lighter;
    font-size: 20px;
    margin: 10px 0;
    color: white;
    text-align: left;
}

h3{
    margin: 0;
    font-family:  "Para" !important;
    font-weight: lighter;
    font-size: 26px;
    color: white;
    text-align: left;
}
h2{
    font-family: "Heading";
    font-size: 30px;
    color: #002245;
    text-align: center;
}
.dot{
    transform: scale(1.8);
}

.link-button{
    background-color: white;
    color: #002245;
    border: none;
    padding: 8px 14px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
    font-family: "Para" !important;
}

.link-button:hover{
    background-color: white;
    color: #002245;
}

@media only screen and (min-width: 992px) {
    .tline{
        width: 70vw;
        margin: auto;
    }
  }

  @media only screen and (max-width: 490px) {
    .tline{
        display:  flex;
        width: 95vw;
        margin: auto;
        text-align: left;
        justify-content: left;
        margin-left: 0;
    }
    .tline ::before{
        display: none;
    }
  }

.timelineContainer{
    align-content: center;
    display:flex;
    align-items: center;
    justify-content: center;
    
}
