.event-container{
    width: 90% !important;
}
.brammam-event-card {
    color: #717274 !important;
    background-color: #ffffff;
    border-radius: 10px;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 98%;
    height: 56vh;
}

.brammam-event-card-top {
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    color: #ffffff !important;
    background-color: #DFE3E9;
}

.brammam-event-card-title {
    color: #ffffff !important;
    font-size: 28px !important;
    font-family: 'Para' !important;
    text-align: center;
    margin: 0px 0px 0px 20px;
    width: 50%;
    padding: 5px 10px 5px 10px;
    background-color: #08315b;
    border-radius: 10px;
    margin-top: 15px;
}

.brammam-event-heading1 {
    color: #717274 !important;
    font-size: 24px !important;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: left;
    padding: 20px;
    overflow: auto;
    min-height: 80px;
}

.flex-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}