Signup Form Styling .sign-up--form-content {
	display: grid;
	grid-template-columns: 1fr 2fr;
	/* Adjust columns for profile picture and form fields */
	grid-template-rows: auto;
	column-gap: 16px;
	/* Increased space between columns */
	row-gap: 16px;
}

.sign-up--top-section {
	display: flex;
	align-items: center;
	/* Center items vertically */
	gap: 100px;
	/* Space between the items */
}

/* Profile Picture Styling */
.sign-up--image-preview {
	grid-column: 1 / span 1;
	/* Profile picture takes the first column */
	grid-row: 1 / span 2;
	/* Profile picture spans two rows */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
}

.sign-up--image-preview input {
	display: none;
}

.sign-up--image-preview div {
	width: 120px;
	/* Adjust width and height */
	height: 120px;
	border: 1px solid #ddd;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sign-up--image-preview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Form Fields Styling */
.sign-up--form-content>div {
	grid-column: 2;
	/* All form fields take the second column */
}

.sign-up--form-content>div:has(input[name="name"]),
.sign-up--form-content>div:has(input[name="phoneNo"]) {
	grid-row: 1;

	/* Name and phone number are on the first row */
}

.sign-up--form-content>div:has(input[type="email"]),
.sign-up--form-content>div:has(input[type="password"]) {
	grid-row: 2;
	/* Email and password are on the second row */
	grid-column: span 2;
	/* Span both columns */
}

.sign-up--form-content>.student-status-select {
	grid-row: 3;
	/* Student status on the third row */
	grid-column: span 2;
	/* Span both columns */
}

.sign-up--form-content>div:has(input[name="indexNumber"]) {
	grid-row: 4;
	/* Index number on the fourth row */
	grid-column: span 2;
	/* Span both columns */
}

/* Button Styling */
.contact-send-button {
	background-color: #022345;
	/* Replace with your color */
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
}

.contact-send-button:hover {
	background-color: #021b2a;
	/* Darker shade for hover effect */
}

.response-message {
	margin-top: 20px;
	font-size: 16px;
}

/* Media Queries for Mobile Devices */
@media only screen and (max-width: 600px) {
	.sign-up--form-content {
		grid-template-columns: 1fr;
		/* Single column layout on small screens */
		grid-template-rows: auto auto auto auto auto auto;
		/* Adjust rows accordingly */
		column-gap: 4px;
		row-gap: 16px;
	}

	.sign-up--form-content>div {
		grid-column: 1;
	}

	.sign-up--image-preview {
		grid-column: 1;
		grid-row: 1;
		margin-bottom: 16px;
	}

	.sign-up--form-content>div:has(input[name="name"]),
	.sign-up--form-content>div:has(input[name="phoneNo"]) {
		grid-row: 2;
	}

	.sign-up--form-content>div:has(input[type="email"]),
	.sign-up--form-content>div:has(input[type="password"]) {
		grid-row: 3;
	}

	.sign-up--form-content>.student-status-select {
		grid-row: 4;
	}

	.sign-up--form-content>div:has(input[name="indexNumber"]) {
		grid-row: 5;
	}
}

/* இளங்கலை மாணவர் */