.sponsors-container-div {
    
}

.sponsors-container {
}

.sponsors-heading {
    color: #022345 !important;
    font-size: 80px ;
    font-family: 'Heading' !important;
    letter-spacing: normal;
    text-align: center;
}

.sponsors-card {
    color: #717274 !important;
    background-color: #ffffff;
    border-radius: 10px;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sponsors-card-title {
    color: #ffffff !important;
    font-size: 28px ;
    font-family: 'Para' !important;
    text-align: center;
    margin: 0 auto 0 auto;
    width: 50%;
    padding: 5px 10px 5px 10px;
    background-color: #08315b;
    border-radius: 10px;
    margin-top: 15px;
}

.sponsors-card-top {
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    color: #ffffff !important;
    background-color: #DFE3E9;
}

.sponsors-heading1 {
    color: #717274 !important;
    font-size: 24px ;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: left;
    padding: 20px;
    overflow: auto;
}

.sponsors-img-cont {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sponsors-img {
    max-height: 250px;
    max-width: 250px;
}

.imageslide{
    width: 1150px !important;
    height: 600px !important;
}

a.link {
    color: #08315b;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .sponsors-card-title {
        font-size: 20px;
    }
    .sponsors-heading1 {
        font-size: 18px;
    }
    .sponsors-img-cont {
        height: 100px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1296px) {
  
}