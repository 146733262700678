.score-landing-container-div {
    padding-bottom: 2rem;
}

.score-landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-heading1 {
    color: #022345 !important;
    font-size: 50px;
    font-family: 'Heading' !important;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.5;
    padding-bottom: 2rem;
}

.score-container {
    display: flex;
    background-color: #E3E7EB;
    height: 100%;
    width: 100%;
}

.score-icon {
    width: auto;
    height: 100px;
    margin-right: 10px;
}

.score-text {
    color: #022345 !important;
    font-size: 24px;
    font-family: Para;
}

.score-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.score-row {
    flex: 1;
    display: flex;
    align-items: center;
}

.score-row-center {
    justify-content: center;
}

.ppl-score-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

@media only screen and (max-width: 950px) {
    .score-container {
        flex-direction: column;
        padding-top: 50px;
    }

    .score-row {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
    }

    .score-text {
        text-align: center;
        margin-top: 5px;
    }

    .ppl-score-icon-container {

    }
}
