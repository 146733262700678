.prizeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    width: 70vw;
    background-color: #002245;
    text-align: center;
    padding: 10px 0px;
    h3{
        padding: 20px ;
        color: #E3E7EB;
        text-align: left;
    }
    /* border: 3pt solid red */
}

@media (min-width: 1024px) {
    .prizeInfo{
        display: flex;
        justify-content: center;
       
    }
    .prizeItem{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .prizeContainer h3{
        text-align: center;
    }
}

.prizeItem{
    display: flex;
    justify-content: center;
    width: 30%;
}
.prizeInfo{
    margin: 10px;
    display: flex;

}
.wishInfo{
    width: 80vw;
    background-color: #002245;
    color: white;
    text-align: center;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .prizeContainer{
        width: 90vw;
    }
    .prizeInfo{
        display: block;
    }

    .prizeItem{
        display: block;
        width: 100%;
        margin: 10px 0;
    }

    h3{
        text-align: center !important;
    }
}