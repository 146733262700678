html {
	overflow-x: hidden;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
	color: inherit;
	text-decoration: none;
}

dialog {
	width: min(40rem, 82vw);
	border-radius: 8px;
	border: 0;
	--shadow-color: 30deg 3% 70%;
	--shadow-elevation-low: 0.6px 0.7px 1.3px hsl(var(--shadow-color) / 0.07),
		1.9px 2.5px 4.5px -0.5px hsl(var(--shadow-color) / 0.37);
	--shadow-elevation-medium: 0.6px 0.7px 1.3px hsl(var(--shadow-color) / 0.07),
		3.2px 4.1px 7.4px -0.2px hsl(var(--shadow-color) / 0.25),
		9.7px 12.3px 22.3px -0.5px hsl(var(--shadow-color) / 0.44);
	--shadow-elevation-high: 0.6px 0.7px 1.3px hsl(var(--shadow-color) / 0.08),
		6.3px 8px 14.5px -0.1px hsl(var(--shadow-color) / 0.2),
		12.4px 15.8px 28.6px -0.2px hsl(var(--shadow-color) / 0.33),
		22.7px 28.8px 52.3px -0.4px hsl(var(--shadow-color) / 0.45),
		41px 52px 94.4px -0.5px hsl(var(--shadow-color) / 0.57);
	box-shadow: var(--shadow-elevation-high);
}

dialog::backdrop {
	backdrop-filter: blur(5px);
}

.pointable {
	cursor: pointer;
}
