h1 {
    font-family: "Heading" !important;
    font-size: 40px;
}

p {
    font-family: "Para" !important;
    font-size: 22px;
}

.teams {
    margin: 0 15%;
}

.teams-card {
    background-color: #e3e7eb;
    border-radius: 5px;
    padding: 3%;
    margin-bottom: 4%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.intro {
    text-align: center;
    margin: 20px;
    width: 93%;
}

.teams-card img {
    width: 300px;
    padding-right: 20px;
}

.teams-card-title {
    font-size: 30px;
    margin: 0;
    font-family: "Heading" !important;
    color: #022345 !important;
}

.teams-card-description{
    font-size: 20px;
    font-family: "Para" !important;
    /* color: #022345 !important; */
}
