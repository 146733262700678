.province-landing-heading1 {
    color: #022345 !important;
    font-size: 30px !important;
    font-family: 'Heading' !important;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.5;
    padding-top: 2rem;
}

.province-landing-heading-letter {
    font-size: 70px;
    line-height: 1;
}

/* styles.css */
.province-container {
    margin-bottom: 20px;
  }
  
  .province-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  @media only screen and (max-width: 600px) {
    .images-container {
      flex-direction: column;
      align-items: center;
    }
  }
  
  
  .district-container {
    margin: 10px;
    text-align: center;
    flex-basis: calc(33.33% - 20px);
    box-sizing: border-box;
    position: relative;
    width: 300px;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 5px;
  }

  .district-container:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition-duration: 0.05s;
  }
  
  .imgx{
    width: 100%;
    height: 100%;
    max-height: 250px;
    position: relative;
    border-radius: 5px;
  }
  
  .district-name {
    /* max-width: 30%; */
    margin-top: 8px;
    margin-left: 8px;
    color: #ffffff !important;
    font-family: 'Para' !important;
    font-size: 25px;
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    border-radius: 8%;
    background-color: #022345; 
    padding: 5px 10px; /* Optional: Add padding to the district name */
  
    /* Responsive adjustments */
    /* @media (max-width: 768px) {
      margin-left: 15%;
    }
  
    @media (max-width: 480px) {
      margin-left: 5%;
      font-size: 10px;
    } */
  }

  .set-width {
    width: 70%;
    padding: 10px;
    margin: auto;
  }
  
  @media only screen and (max-width: 768px) {
    .set-width {
      width: 90%;
    }
  }
  
  