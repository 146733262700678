.event-container-div {
    min-height: 170vh;
    background-color: #F6F6F8;
}

.event-container {
    min-height: 100vh;
    margin-top: 2em;
}

.event-heading {
    color: #022345 !important;
    font-size: 80px !important;
    font-family: 'Heading' !important;
    letter-spacing: normal;
    text-align: center;
}

.intro-heading1 {
    color: #717274 !important;
    font-size: 24px !important;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 2em;
    padding: 0 4em;
}

.event-card {

    color: #717274 !important;
    background-color: #ffffff;
    border-radius: 10px;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: center;
    min-height: 600px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.event-card:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transform: matrix(1.01, 0, 0, 1.01, 0, 0);
    transition-duration: 0.5s;
}

.event-card-title {

    color: #ffffff !important;
    font-size: 28px !important;
    font-family: 'Para' !important;
    text-align: center;
    margin: 0px 0px 0px 20px;
    width: 50%;
    padding: 5px 10px 5px 10px;
    background-color: #08315b;
    border-radius: 10px;
    margin-top: 15px;
}

.event-card-top {
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    color: #ffffff !important;
    background-color: #DFE3E9;

}

.event-heading1 {

    color: #717274 !important;
    font-size: 24px !important;
    font-family: 'Para' !important;
    letter-spacing: normal;
    text-align: left;
    padding: 20px;
    overflow: auto;
    min-height: 220px;
}




.event-img {
    height: 250px;
    width: 250px;
}@media only screen and (max-width: 400px) {
    .event-container-div {
        /* min-height: 620vh !important; */
    }

}

@media only screen and (max-width: 600px) {
    .event-container-div {
        min-height: 465vh ;
    }
}


@media only screen and (min-width: 600px) {
    .event-container-div {
        min-height: 256vh;
    }
    
}


@media only screen and (min-width: 850px) {
    .event-container-div {
        min-height: 250vh;
    }
    
}

@media only screen and (min-width: 900px) {
    .event-container-div {
        min-height: 175vh;
    }
    
}