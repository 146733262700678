.event-landing-container-div {
    height: 100vh;
    background-image: url(../../images/Introduction/intro_back.png),
      url(../../images/Introduction/intro_back_flip.png);
    background-position: right, left;
    background-size: 20%;
    background-repeat: no-repeat;
  }
  
  .event-landing-container {
    min-height: 90vh;
  }
  
  .event-landing-heading1 {
    color: #022345 !important;
    font-size: 50px !important;
    font-family: "Heading" !important;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.5;
    padding-top: 2rem;
  }
  
  .event-icon {
    height: 50vh;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .event-landing-heading-letter {
    font-size: 70px;
    line-height: 1;
  }
  
  .event-intro-decription {
    color: #717274 !important;
    font-size: 30px;
    font-family: "Para" !important;
    letter-spacing: normal;
    text-align: center;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .event-landing-container-div {
      height: fit-content !important;
      background-image: none;
      margin-bottom: 20px;
    }

    .event-landing-container {
      min-height: 20vh;
  }

    .event-intro-decription {
      font-size: 25px;
    }
  
    .event-landing-heading1 {
      font-size: 36px !important;
      padding-top: 0.8rem;
    }
  
    .event-landing-heading-letter {
      font-size: 50px;
    }
  
    .event-icon {
      height: 30vh;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .intro-decription {
      /* font-size: 30px !important; */
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .intro-decription {
      /* font-size: 30px !important; */
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .intro-decription {
      /* font-size: 30px !important; */
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .intro-description {
      /* font-size: 30px !important; */
    }
  }
  